<template>
  <div class="home">
    <b-container>
      <div class="row">
        <div class="col-lg-6 col-md-8 m-auto auth-form">
          <h3 class="text-center mb-4">Forgot Password</h3>
          <b-card>
            <div class="">
              <div class="text-success mb-3" v-show="show">
                A password reset massage has been sent to your email address.
                Please click the link in that message to reset your password.
                <br><br>
                If you do not receive the password reset message within a few moments,
                please check your spam folder or other filtering tools.
              </div>

              <b-form @submit="onSubmit">
                <b-form-group id="input-group-1" label="Email" label-for="input-1">
                  <b-input-group>
                    <b-input-group-prepend>
                      <span class="input-group-text">
                        <b-icon icon="envelope"></b-icon>
                      </span>
                    </b-input-group-prepend>
                    <b-form-input class="LoginInput" size="lg" autofocus v-model="email">
                    </b-form-input>
                  </b-input-group>
                </b-form-group>
                <div class="text-center mt-4">
                  <b-button class="btn-om-solid" type="submit" size="lg">SEND PASSWORD RESET LINK</b-button>
                </div>
              </b-form>

            </div>
          </b-card>
        </div>
      </div>

    </b-container>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        email: '',
        show: false
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        let data = { email: this.email, reset_password_url: window.location.origin + this.$router.resolve({ name: 'password.recover' }).href }
        this.$store.dispatch('auth/forgotPassword', data)
          .then(() => {
            this.show = true
            this.email = ''
          })
          .catch(error => {
            let message = error.data.errors.email
            this.$bvToast.toast(message, {
              noCloseButton: true,
              variant: 'danger',
            })
          })
      },
    }
  }
</script>
